import { Page } from "@dangerfarms/website-core";
import { Box, Button, ContentBlock, Stack, Text } from "@dangerfarms/ui-system";
import { Link } from "gatsby";
import React from "react";

const NotFoundPage = () => {
  return (
    <Page
      description="Sorry, we couldn't find the page you're looking for"
      title="Not found"
    >
      <Box paddingX="gutter" paddingY={8}>
        <ContentBlock>
          <Stack>
            <Stack space={0.5}>
              <Text as="h1" variant="heading">
                Not found
              </Text>
              <Text maxWidth="30em" variant="lede">
                Sorry, we couldn&apos;t find the page you&apos;re looking for.
              </Text>
            </Stack>
            <Button as={Link} to="/">
              Take me home
            </Button>
          </Stack>
        </ContentBlock>
      </Box>
    </Page>
  );
};

export default NotFoundPage;
